import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';

import successIcon from '@public/imgs/icon-success-new.svg';
import urgentLeaveIcon from '@public/imgs/urgent-zh-leave-icon.svg';
import urgentEnLeaveIcon from '@public/imgs/urgent-en-leave-icon.svg';
import styles from './style.module.scss';
import { ClassTypeEnum, LeaveProps } from '../../Home/interface';
import CommonDetail from './commonDetail';

const LOCALE_ICONS = {
  en: {
    urgent: urgentEnLeaveIcon.src,
  },
  zh: {
    urgent: urgentLeaveIcon.src,
  },
};

const LeaveRecordComponent = (props: LeaveProps) => {
  const { t, i18n } = useTranslation('home');
  const { courseUnit, urgent, originalClassType } = props;

  const icons = useMemo(() => {
    switch (i18n.language) {
      case 'zh':
        return LOCALE_ICONS.zh;
      case 'en':
        return LOCALE_ICONS.en;
      default:
        return LOCALE_ICONS.zh;
    }
  }, [i18n.language]);

  const header = (
    <div className={cls(styles.boxHeader, styles.success)}>
      <div className={styles.headerIcon}>
        <img src={successIcon.src} alt="" />
      </div>
      <div className={styles.headerRight}>
        <div className={cls(styles.headerTitle, styles.success)}>
          <span>{t('已请假')}</span>
        </div>
        <span className={cls(styles.headerSubTitle, { [styles.mT2]: !urgent })}>
          {originalClassType === ClassTypeEnum.GROUP ? t('自主请假-班课请假成功') : t('请假成功')}
        </span>
        {urgent && (
          <span
            className={cls(
              styles.headerTip,
              { [styles.auto]: !urgent },
              { [styles.chineseWidth]: i18n.language === 'zh' },
            )}
            dangerouslySetInnerHTML={{ __html: t('请假成功提示') }}
          />
        )}
      </div>
      {urgent && (
        <div className={styles.promptContainer}>
          <span className={styles.courseUnit}>-{courseUnit}</span>
          {t('课时')}
        </div>
      )}
      {urgent && <img className={styles.urgentIcon} src={icons.urgent} alt="" />}
    </div>
  );

  return <CommonDetail {...props} header={header} />;
};

export default LeaveRecordComponent;
