import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';

import cancelIcon from '@public/imgs/icon-cancel.svg';
import urgentLeaveIcon from '@public/imgs/urgent-zh-leave-icon.svg';
import urgentEnLeaveIcon from '@public/imgs/urgent-en-leave-icon.svg';
import styles from './style.module.scss';
import { LeaveProps } from '../../Home/interface';
import { formatDateAndWeekAndTime } from '@/utils/date';
import CommonDetail from './commonDetail';

const LOCALE_ICONS = {
  en: {
    urgent: urgentEnLeaveIcon.src,
  },
  zh: {
    urgent: urgentLeaveIcon.src,
  },
};

const CancelRecord = (props: LeaveProps) => {
  const { t, i18n } = useTranslation('home');
  const { courseUnit, urgent, cancelTime } = props;

  const icons = useMemo(() => {
    switch (i18n.language) {
      case 'zh':
        return LOCALE_ICONS.zh;
      case 'en':
        return LOCALE_ICONS.en;
      default:
        return LOCALE_ICONS.zh;
    }
  }, [i18n.language]);

  const boxItemWidth = i18n.language === 'en' ? '120px' : '73.7px';

  const header = (
    <div className={cls(styles.boxHeader, styles.processing)}>
      <div className={styles.headerIcon}>
        <img src={cancelIcon.src} alt="" />
      </div>
      <div className={styles.headerRight}>
        <div className={cls(styles.headerTitle, styles.processing)}>
          <span>{t('自主请假-撤回标题')}</span>
        </div>
        <span className={cls(styles.headerSubTitle, { [styles.mT2]: !urgent })}>{t('自主请假-撤回描述')}</span>
        {urgent && (
          <span
            className={cls(
              styles.headerTip,
              { [styles.auto]: !urgent },
              { [styles.chineseWidth]: i18n.language === 'zh' },
            )}
            dangerouslySetInnerHTML={{ __html: t('请假成功提示') }}
          />
        )}
      </div>
      {urgent && (
        <div className={styles.promptContainer}>
          <span className={styles.courseUnit}>-{courseUnit}</span>
          {t('课时')}
        </div>
      )}
      {urgent && <img className={styles.urgentIcon} src={icons.urgent} alt="" />}
    </div>
  );

  const footerBottom = (
    <div className={cls(styles.boxItem, 'last:mb-0')}>
      <span className="text-right" style={{ width: boxItemWidth }}>
        {t('自主请假-撤回时间')}:
      </span>
      <span className={styles.itemTip}>{formatDateAndWeekAndTime(cancelTime as string, i18n.language)}</span>
    </div>
  );

  return <CommonDetail {...props} header={header} footerBottom={footerBottom} />;
};

export default CancelRecord;
