import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';

import successIcon from '@public/imgs/icon-success-new.svg';
import urgentLeaveIcon from '@public/imgs/urgent-zh-leave-icon.svg';
import urgentEnLeaveIcon from '@public/imgs/urgent-en-leave-icon.svg';
import styles from './style.module.scss';
import { LeaveProps } from '../../Home/interface';
import { toDay, toHour } from '@/utils/utils';
import LeaveRecordComponentNew from './leaveRecordNew';

const LOCALE_ICONS = {
  en: {
    urgent: urgentEnLeaveIcon.src,
  },
  zh: {
    urgent: urgentLeaveIcon.src,
  },
};

const LeaveRecordComponent = (props: LeaveProps) => {
  const { t, i18n } = useTranslation('home');
  const {
    classType,
    reason,
    lessonTitle,
    lessonUnitTitle,
    lessonTeacherName,
    lessonStartDateTime = '',
    lessonEndDateTime = '',
    courseUnit,
    urgent,
    whenCreated,
    dataType,
  } = props;

  const icons = useMemo(() => {
    switch (i18n.language) {
      case 'zh':
        return LOCALE_ICONS.zh;
      case 'en':
        return LOCALE_ICONS.en;
      default:
        return LOCALE_ICONS.zh;
    }
  }, [i18n.language]);

  if (dataType === 'NEW') {
    return <LeaveRecordComponentNew {...props} />;
  }

  const boxItemWidth = i18n.language === 'en' ? '90px' : 'auto';

  return (
    <div className={styles.boxWrapper}>
      <div className={cls(styles.boxHeader, styles.success)}>
        <div className={styles.headerIcon}>
          <img src={successIcon.src} alt="" />
        </div>
        <div className={styles.headerRight}>
          <div className={cls(styles.headerTitle, styles.success)}>
            <span>{t('已请假')}!</span>
          </div>
          <span className={cls(styles.headerSubTitle, { [styles.mT2]: !urgent })}>{t('请假成功')}</span>
          {urgent && (
            <span
              className={cls(
                styles.headerTip,
                { [styles.auto]: !urgent },
                { [styles.chineseWidth]: i18n.language === 'zh' },
              )}
              dangerouslySetInnerHTML={{ __html: t('请假成功提示') }}
            />
          )}
        </div>
        {urgent && (
          <div className={styles.promptContainer}>
            <span className={styles.courseUnit}>-{courseUnit}</span>
            {t('课时')}
          </div>
        )}
        {urgent && <img className={styles.urgentIcon} src={icons.urgent} alt="" />}
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('请假课程')}:</span>
        <span className={styles.itemTip}>
          {lessonUnitTitle?.split('-').join(' ')} - {lessonTitle}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('课程类型')}: </span>
        <span className={styles.itemTip}>{classType}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('主讲老师')}:</span>
        <span className={styles.itemTip}>{lessonTeacherName}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('上课时间')}:</span>
        <span className={cls(styles.itemTip, styles.bold)}>
          {toDay(lessonStartDateTime, i18n.language, 'YYYY-M-D (dddd)')} {toHour(lessonStartDateTime)}-
          {toHour(lessonEndDateTime)}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('请假时间')}:</span>
        <span className={styles.itemTip}>
          {toDay(whenCreated || '', i18n.language, 'YYYY-M-D (dddd)')} {toHour(whenCreated || '')}
        </span>
      </div>
      <div className={styles.line} />
      <div className={cls(styles.boxItem, styles.mB0)}>
        <span style={{ width: boxItemWidth }}>{t('请假申请')}:</span>
        <span className={styles.itemTip}>{reason}</span>
      </div>
    </div>
  );
};

export default LeaveRecordComponent;
