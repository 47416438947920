import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';

import successIcon from '@public/imgs/pc/icon-success-fill.svg';
// import { Button } from '@/components';
import styles from './style.module.scss';
import { LeaveProps, AdjustResultEnum } from '../../Home/interface';
import { formatDateAndWeekAndTime } from '@/utils/date';
import CommonDetail from './commonDetail';

const ADJUST_RESULT_LABEL = {
  [AdjustResultEnum.PROCESS]: {
    title: '已请假',
    label: '请假成功',
    icon: successIcon.src,
  },
  [AdjustResultEnum.SUCCESS]: {
    title: '调课成功',
    label: '调课成功提示',
    icon: successIcon.src,
  },
  [AdjustResultEnum.FAIL]: {
    title: '已请假',
    label: '请假成功',
    icon: successIcon.src,
  },
};

const LeaveRecordComponent = (props: LeaveProps) => {
  const { t, i18n } = useTranslation('home');
  const { adjustStatus = AdjustResultEnum.PROCESS, newStartDateTime } = props;

  const currentLabel = useMemo(() => {
    return ADJUST_RESULT_LABEL[adjustStatus];
  }, [adjustStatus]);

  const boxItemWidth = i18n.language === 'en' ? '120px' : '73.7px';

  const header = (
    <div className={cls(styles.boxHeader, styles.success)}>
      <div className={styles.headerIcon}>
        <img src={currentLabel.icon} alt="" />
      </div>
      <div className={styles.headerRight}>
        <div className={cls(styles.headerTitle, styles.success)}>{t(currentLabel.title)}</div>
        <span className={styles.headerSubTitle} dangerouslySetInnerHTML={{ __html: t(currentLabel.label) }} />
      </div>
    </div>
  );

  const bodyBottom = newStartDateTime ? (
    <div className={styles.boxItem}>
      <span className="text-right" style={{ width: boxItemWidth }}>
        {t('自主请假-新上课时间')}:
      </span>
      <span className={cls(styles.itemTip)}>{formatDateAndWeekAndTime(newStartDateTime as string, i18n.language)}</span>
    </div>
  ) : null;

  return <CommonDetail {...props} header={header} bodyBottom={bodyBottom} />;
};

export default LeaveRecordComponent;
