import qs from 'query-string';
import { AxiosRequestConfig } from 'axios';
import client from './axios';

import { ContentData, PageData } from './types/index';
import {
  CheckLeaveReq,
  CheckLeaveRes,
  ClassinUrlReq,
  ClassinUrlRes,
  LeaveApplyReq,
  LeaveApplyRes,
  LessonDetailRes,
  PlaybackVideoRes,
  ScheduleDetailRes,
} from './types/curriculum';

import { SUBJECT } from '@/utils/enum';
import { WK_GATEWAY_URL as WK_GATEWAY } from '@/utils/env';
import { getAPIBaseUrl } from '@/utils/url';

const WK_GATEWAY_URL = getAPIBaseUrl(WK_GATEWAY);

type ClassinUrl = ContentData<ClassinUrlRes>;
export const getClassinUrl = (params: ClassinUrlReq) => {
  return client
    .get<ClassinUrl, ClassinUrl>(`api/student/master/v2/schedule/list_playback_videos?${qs.stringify(params)}`)
    .then((res) => res?.content);
};

type CheckLeave = ContentData<CheckLeaveRes>;
export const checkLeave = (data: CheckLeaveReq) => {
  return client.post<CheckLeave, CheckLeave>('/api/student/master/v2/check_leave', data).then((res) => res?.content);
};

type LeaveApply = ContentData<LeaveApplyRes>;
export const leaveApply = (data: LeaveApplyReq) => {
  return client.put<LeaveApply, LeaveApply>('/api/student/master/v2/leave', data).then((res) => res?.content);
};

type PlaybackVideo = ContentData<PlaybackVideoRes>;
export const getPlaybackVideos = (stuCourseScheduleId: string) => {
  return client
    .get<PlaybackVideo, PlaybackVideo>(`/api/student/schedule/schedule/list_playback_videos/${stuCourseScheduleId}`)
    .then((res) => res?.content);
};

type ScheduleDetail = ContentData<ScheduleDetailRes>;
export const getScheduleDetail = (courseScheduleId: string) => {
  return client
    .get<ScheduleDetail, ScheduleDetail>(`/api/student/master/v2/simple/schedule/${courseScheduleId}`)
    .then((res) => res?.content);
};

export const sendReady = (courseSectionId: string) => {
  return client.post(`/api/student/master/v2/schedule/report/view/${courseSectionId}`);
};

type LessonListRes = PageData<Record<string, LessonDetailRes[]>[]>;
export const getLessonList = (pageNum: number, recentType: 'normal' | 'completed', options: AxiosRequestConfig) => {
  return client.post<LessonListRes, LessonListRes>(
    `/api/student/schedule/recently/web/${pageNum}`,
    { recentType },
    options,
  );
};

type LessonListResV2 = PageData<LessonDetailRes[]>;
export const getLessonListV2 = (
  pageNum: number,
  recentType: 'normal' | 'completed',
  subject: SUBJECT,
  options: AxiosRequestConfig,
) => {
  const args: { recentType: 'normal' | 'completed'; subject?: Omit<SUBJECT, 'all'>; pageSize: number } = {
    recentType,
    pageSize: 10,
  };
  if (subject !== SUBJECT.ALL) {
    args.subject = subject;
  }

  return client.post<LessonListResV2, LessonListResV2>(
    `/api/student/schedule/recently/web/v2/${pageNum}`,
    args,
    options,
  );
};

export const getHomeworkList = (
  pageNum: number,
  recentType: 'completed' | 'normal',
  option: AxiosRequestConfig,
  subject?: SUBJECT,
) => {
  return client.post<PageData<LessonDetailRes[]>, PageData<LessonDetailRes[]>>(
    `/api/student/learning_task/homework/${pageNum}`,
    { recentType, subject: subject !== SUBJECT.ALL ? subject : undefined },
    option,
  );
};

export const hasAfterHomework = (option: AxiosRequestConfig) => {
  return client
    .post<ContentData<number>, ContentData<number>>('/api/student/learning_task/has_learning_task', {}, option)
    .then((res) => res?.content);
};

export const getStudentReport = (courseScheduleId: string, stuScheduleId: string) => {
  return client
    .get<
      ContentData<{ noneRatingComment?: boolean }>,
      ContentData<{ noneRatingComment?: boolean; isTrailCourse: boolean; trialReportUrl?: string }>
    >(`api/student/master/v2/schedule/rating_commentV2/${courseScheduleId}/${stuScheduleId}`)
    .then((res) => res?.content);
};

/**
 * 判断教室是否存在当前用户
 *
 * @param {{ courseScheduleId: string; studentId: string }} { courseScheduleId, studentId }
 * @return {boolean}
 */
export const getHasUserInClass = ({ courseScheduleId, studentId }: { courseScheduleId: string; studentId: string }) => {
  return client
    .get<boolean, boolean>(`${WK_GATEWAY_URL}/wk-talkcloud/api/room/${courseScheduleId}/user/${studentId}/presence`)
    .then((res) => res);
};

/**
 * 查询学号关联的学生是否为ES用户
 */
export const getIsEsUser = async (studentCode: string) => {
  if (!studentCode) {
    return '';
  }
  return await client.get(`/api/student/master/v2/isEsUser/${studentCode}`);
};
