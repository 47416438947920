import React from 'react';
import { useTranslation } from 'next-i18next';
import ReactDOM from 'react-dom';
import CustomModal from '@/components/NewModal';
import { LeaveProps, LeaveTypeEnum } from '../../Home/interface';
import PrimaryApplyComponent from './primaryApply';
import AdjustApplyComponent from './adjustApply';
import LeaveRecordComponent from './leaveRecord';
import AdjustRecordComponent from './adjustRecord';
import CancelRecordComponent from './cancelRecord';

const Leave = (props: LeaveProps) => {
  const { type, visible, onClose } = props;
  const { t } = useTranslation('home');
  const LeaveType = [
    {
      key: LeaveTypeEnum.PRIMARY_LEAVE_APPLY,
      label: t('请假申请'),
      component: PrimaryApplyComponent,
    },
    {
      key: LeaveTypeEnum.URGENT_LEAVE_APPLY,
      label: t('请假申请'),
      component: PrimaryApplyComponent,
    },
    {
      key: LeaveTypeEnum.ADJUST_LESSON_APPLY,
      label: t('调课申请'),
      component: AdjustApplyComponent,
    },
    {
      key: LeaveTypeEnum.LEAVE_RECORD,
      label: t('请假详情'),
      component: LeaveRecordComponent,
    },
    {
      key: LeaveTypeEnum.ADJUST_RECORD,
      label: t('调课详情'),
      component: AdjustRecordComponent,
    },
    {
      key: LeaveTypeEnum.CANCEL_LEAVE_RECORD,
      label: t('请假详情'),
      component: CancelRecordComponent,
    },
  ];
  const { label, component: Template = PrimaryApplyComponent } = LeaveType.find(({ key }) => key === type) || {};

  return ReactDOM.createPortal(
    <CustomModal title={label || ''} visible={visible} onClose={onClose}>
      <Template {...props} />
    </CustomModal>,
    document.body,
  );
};

export default Leave;
